import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import Layout from '../components/Layout.jsx'
import LayoutWithSideMenu from '../components/LayoutWithSideMenu'
import TroublesList from '../components/TroublesList'
import Container from '../components/Container'

export default ({ data }) => {
  const troubles = data.allMarkdownRemark.edges.map(edge => edge.node.frontmatter)
  const breadcrumbs = [
    {
      name: 'Главная',
      path: '/'
    },
    {
      name: 'Типичные неисправности',
      path: '/neispravnosti'
    }
  ]
  return (
    <Layout>
      <Container>
        <Helmet>
          <title>Типовые неисправности компьютеров: причины и способы устранения — АлексКомп в Павлограде</title>
          <meta name="description" content="Типичные неисправности для всех марок и моделей ноутбуков. Структурное представление видов поломок. Детальный подход к устранению причин неисправностей." />
        </Helmet>
        <LayoutWithSideMenu heading="Типичные неисправности" menu={troubles} breadcrumbs={breadcrumbs}>
          <p>Для большинства людей словосочетания — ребоулинг чипа или перепайка шины — это слишком сложно. Им всего лишь надо чтобы их ноутбук работал! Если и вам нужно разобраться с вашей проблемой максимально просто — найдите ее в перечне самых распространенных неисправностей ноутбуков. Там вы узнаете причины возникновения проблемы, получите рекомендации экспертов по устранению поломки, и узнаете стоимость и сроки выполнения работ.</p>
          <TroublesList troubles={troubles}/>
          <p>Если вы не знаете, какие ремонтные работы требуются вашему компьютеру, то просто привозите его к нам. Мы проведем бесплатную экспресс диагностику и предложим оптимальные варианты устранения данной неисправности.</p>
          <p>Любая типовая неисправность может иметь различные причины возникновения. Наши эксперты обладают достаточным опытом и знаниями архитектуры ноутбуков, чтобы не упустить из виду ни одну из них. Именно детальный подход к диагностированию причин неисправности делает АлексКомп одним из ведущих сервис-центров по обслуживанию компьютеров в Павлограде.</p>
        </LayoutWithSideMenu>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query getMalfunctions {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/malfunctions/" } },
      sort: {order: DESC, fields: [frontmatter___date]}) {
      edges {
        node {
          frontmatter {
            name
            path
            icon {
              publicURL
            }
          }
        }
      }
    }
  }
`
