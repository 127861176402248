import React from 'react'
import Link from '../../components/Link'
import styles from './TroublesList.module.css'

export default ({ troubles }) => {
  return (
    <ul className={styles['TroublesList']}>
      {troubles.map((trouble, index) =>
        <li className={styles['TroublesList__Item']} key={index}>
          <Link to={trouble.path} className={styles['TroublesList__Link']}>
            <img className={styles['TroublesList__Icon']} src={trouble.icon && trouble.icon.publicURL} />
            <span>{trouble.name}</span>
          </Link>
        </li>
      )}
    </ul>
  )
}
